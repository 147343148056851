<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.rebatesByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="rebatesHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="rebates"
            :search="searchRebateString"
            class="elevation-1 cursor-pointer"
            @click:row="editRebateItem"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.rebates') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchRebateString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="rebateDialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(rebateFormTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeRebateEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(saveRebate)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" lg="4" md="4" sm="12">
                                <validation-provider
                                  :rules="{
                                    required: true,
                                    numeric: true,
                                    min_value: 0,
                                    range: { rebates: rebates, index: editedItemIndex },
                                  }"
                                  v-slot="{ errors }"
                                  name="rebate_min">
                                  <v-text-field
                                    autofocus
                                    v-model="editedItem.min"
                                    clearable
                                    :label="$t('rebatesPage.rebateMin')"
                                    type="text"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12">
                                <validation-provider
                                  :rules="{
                                    required: true,
                                    numeric: true,
                                    min_value: 0,
                                    compare: { min: editedItem.min, max: editedItem.max },
                                    range: { rebates: rebates, index: editedItemIndex },
                                  }"
                                  v-slot="{ errors }"
                                  name="rebate_max">
                                  <v-text-field
                                    v-model="editedItem.max"
                                    clearable
                                    :label="$t('rebatesPage.rebateMax')"
                                    type="text"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="4" md="4" sm="12">
                                <validation-provider
                                  rules="required|numeric_decimal|min_value:0|max_value:100"
                                  v-slot="{ errors }"
                                  name="rebate">
                                  <v-text-field
                                    v-model="editedItem.percentage"
                                    clearable
                                    :label="$t('rebatesPage.rebate')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="rebateDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t('rebatesPage.deleteRebate') }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeRebateDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('rebatesPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn :disabled="!enableRebateSave" class="primary" text @click="deleteRebateItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editRebateItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="deleteItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { rebatesHeaders } from '@/mixins/data-table-headers';
import { defaultRebate } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'RebateItem',
  components: { ButtonSubmit },
  data: () => ({
    searchRebateString: '',
    rebateDialog: false,
    rebateDialogDelete: false,
    rebates: [],
    rebateFormTitle: 'rebatesPage.newItem',
    rebateFormValid: true,
    editedItem: {},
    editedItemDeleted: {},
    editedItemIndex: null,
    enableRebateSave: true,
    loading: false,
  }),
  created() {
    this.editedItem = Object.assign({}, defaultRebate);
    this.loadAllRebates();
  },
  computed: {
    rebatesHeaders() {
      return rebatesHeaders(i18n);
    },
  },
  methods: {
    async loadAllRebates() {
      await this.$store.dispatch('rebates/getAllRebates').then((res) => {
        this.rebates = res.data.map((e) => {
          return e;
        });
      });
    },

    editRebateItem(item) {
      this.editedItem = this.rebates.indexOf(item);
      this.editedItemIndex = this.rebates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.rebateFormTitle = 'rebatesPage.editItem';
      this.editedItem.min = item.min ? Number(item.min) : null;
      this.editedItem.max = item.max ? Number(item.max) : null;
      this.editedItem.percentage = item.percentage ? Number(item.percentage) : null;
      this.rebateDialog = true;
    },

    deleteItem(item) {
      this.editedItemDeleted = this.rebates.indexOf(item);
      this.editedItemDeleted = Object.assign({}, item);
      this.rebateDialogDelete = true;
    },

    async saveRebate() {
      this.enableRebateSave = false;
      this.loading = true;
      let path = 'saveRebate';

      if (this.editedItem.id) {
        Object.assign(this.editedItem, { _method: 'POST' });
        path = 'updateRebate';
      }
      this.editedItem.min = this.editedItem.min ? Number(this.editedItem.min) : null;
      this.editedItem.max = this.editedItem.max ? Number(this.editedItem.max) : null;
      this.editedItem.percentage = this.editedItem.percentage ? Number(this.editedItem.percentage).toFixed(2) : null;

      await this.$store
        .dispatch('rebates/' + path, this.editedItem)
        .then(() => {
          this.loadAllRebates();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeRebateEdit();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableRebateSave = true;
          this.loading = false;
          this.rebateFormTitle = 'rebatesPage.newItem';
        });
    },

    async deleteRebateItemConfirm() {
      await this.$store
        .dispatch('rebates/deleteRebate', this.editedItem)
        .then((res) => {
          if (res.success) {
            this.loadAllRebates();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeRebateDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeRebateDelete();
        });
    },

    closeRebateEdit() {
      this.rebateDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultRebate);
      });
      this.$refs.form.reset();
      this.rebateFormTitle = 'rebatesPage.newItem';
    },

    closeRebateDelete() {
      this.rebateDialogDelete = false;
    },
  },

  watch: {
    rebateDialog(val) {
      val || this.closeRebateEdit();
    },
  },
};
</script>
